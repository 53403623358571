import {useSelector} from "react-redux";
import {selectJsonRequest} from "../../../components/global";
import {useEffect, useState} from "react";
import {Empty, Steps, Toast} from "antd-mobile";
import {useSearchParams} from "react-router-dom";
import './index.css';
import {localTime} from "../../../components/util";

const {Step} = Steps

function OrderExpress({}: any) {
    const jsonRequest = useSelector(selectJsonRequest)
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const [data, setData]: any = useState()

    useEffect(() => {
        if (id) {
            getData()
        }
    }, []);

    const getData = () => {
        jsonRequest.get(`/v1/user/order/${id}/express`, {})
            .then(({data}: any) => {
                setData(data)
            })
            .catch((e) => Toast.show({
                icon: 'fail',
                content: '数据获取失败',
            }))
    }

    const statusMap: any = {
        'WAIT_ACCEPT': '待揽收',
        'ACCEPT': '已揽收',
        'TRANSPORT': '运输中',
        'DELIVERING': '派件中',
        'AGENT_SIGN': '已代签收',
        'SIGN': '已签收',
        'FAILED': '包裹异常'
    }

    const details = data?.logisticsTraceDetails;
    return (
        <div className="order-express-page">
            {!data ? <Empty style={{marginTop: 'calc(50vh - 124px)'}}
                            description={id ? '暂无物流信息' : '商品未发货，暂无物流信息'}/> :
                <div className='express-body'>
                    <div className='express-header'>
                        <div>
                            <span style={{marginRight: '6px'}}>{data.expressCompanyName}</span>
                            {data.number}
                        </div>
                        <span onClick={() => {
                            navigator.clipboard.writeText(data.number);
                            Toast.show({
                                content: '已复制',
                                position: 'bottom',
                            })
                        }} className='copy-button'>复制</span>
                    </div>
                    <div className='express-detail'>
                        <Steps direction='vertical'
                               style={{
                                   '--title-font-size': '18px',
                                   '--description-font-size': '16px',
                               }}>
                            {details?.reverse().map((e: any, index: any) => (
                                <Step
                                    title={<span style={{color: 'var(--adm-color-primary)'}}>
                                        {index == 0 || (index > 0 && details[index].logisticsStatus != details[index - 1].logisticsStatus) ?
                                            statusMap[e.logisticsStatus] : null}
                                    </span>}
                                    key={e.time} status="finish"
                                    description={<>
                                        <div>{e.desc}</div>
                                        <div style={{marginTop: '4px'}}>{localTime(e.time)}</div>
                                    </>}/>
                            ))}
                        </Steps>
                    </div>
                </div>}
        </div>
    )
}

export default OrderExpress;