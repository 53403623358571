import config from '../config'

class JsonRequest {
    user: any
    // 基础地址
    baseUrl: string = ""

    // 用用户和当前账户信息创建一个带有 token 的 JsonRequest 实例
    static tokenClient(user?: { payload: any }, headers?: {}) {
        return new JsonRequest(config.HttpEndpoint(""), {Authorization: `Bearer ${user?.payload}`, ...headers}, user)
    }

    // 用用户和当前账户信息创建一个带有 token 的 JsonRequest 实例
    static rawClient(headers?: {}) {
        return new JsonRequest(config.HttpEndpoint(""), headers, null)
    }

    headers: any = {'Content-Type': 'application/json'}

    // 设置用户信息，包括 token
    setUser(user: { token: any }) {
        this.headers = {...this.headers, Authorization: `Bearer ${user?.token}`}
    }

    constructor(baseUrl: string, headers = {}, user: any) {
        // 合并请求头信息
        this.headers = {...headers, ...this.headers}
        this.user = user
        this.baseUrl = baseUrl
    }

    // 发起请求的方法
    async request(path: string, method: string, values: any, headers = {}) {
        // 设置请求选项
        const requestOptions: any = {method: method, headers: {...headers, ...this.headers}}

        if (method !== "FILE") {
            values = values || {}
        }

        switch (method) {
            case "GET":
            case "DELETE":
                if (values) {
                    for (var key in values) {
                        if (values[key] == null || typeof (values[key]) == "undefined") {
                            delete values[key];
                        }
                    }
                }
                path = `${path}?${new URLSearchParams(values).toString()}`
                break;
            case "POST":
            case "PATCH":
            case "PUT":
                // 将请求体转换为 JSON 字符串
                requestOptions["body"] = JSON.stringify(values);
                break;
            case "FILE":
                requestOptions["body"] = values;
                requestOptions["method"] = "POST"
                delete requestOptions["headers"]["Content-Type"]
                break;
            default:
                break;
        }
        return await fetch(`${this.baseUrl}${path}`, requestOptions)
            .then((resp) => resp.json())
    }

    post(path: any, obj: any, headers = {}) {
        return this.request(path, "POST", obj, headers)
    }

    get(path: any, obj: any, headers = {}) {
        return this.request(path, "GET", obj, headers)
    }

    put(path: any, obj: any, headers = {}) {
        return this.request(path, "PUT", obj, headers)
    }

    delete(path: any, obj: any, headers = {}) {
        return this.request(path, "DELETE", obj, headers)
    }

    upload(path: any, file: string | Blob, obj: { [x: string]: string | Blob }, headers = {}) {
        var data = new FormData()
        data.append('cover', file)
        for (const key in obj) {
            data.append(key, obj[key])
        }

        return this.request(path, "FILE", data, headers)
    }

    file(path: any, formData: any, headers = {}) {
        return this.request(path, "FILE", formData, headers)
    }
}

export default JsonRequest
