import './index.css';
import Select from "../../../images/select.png";
import {Empty, Modal, Popup, reduceMotion, restoreMotion, SwipeAction, Tag, Toast} from "antd-mobile";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectJsonRequest} from "../../global";
import {AddOutline, CheckCircleFill, CheckCircleOutline} from "antd-mobile-icons";
import AddressForm from "../form";

function AddressList({initSelected, onChange}: any) {
    const jsonRequest = useSelector(selectJsonRequest)
    const [data, setData] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [modelData, setModalData]: any = useState(null);
    useEffect(() => {
        getData()
        reduceMotion() // 减少动效 解决Popup弹出输入抖动
        return () => {
            restoreMotion()
        }
    }, [])

    const getData = () => {
        jsonRequest.get('/v1/user/address/list/', {})
            .then(({data}: any) => {
                setData(data);
                if (initSelected)
                    setSelectedIndex(data.indexOf(data.find((e: any) => e.id == initSelected.id)));
                else if (data.length > 0)
                    setSelectedIndex(0);
            })
            .catch((e) => Toast.show({
                icon: 'fail',
                content: '数据获取失败',
            }))
    }

    const deleteAddress = async (id: any) => {
        const result = await Modal.confirm({content: "确认删除此地址吗？"});
        if (!result) return;

        jsonRequest.delete(`/v1/user/address/${id}`, {})
            .then((res) => {
                Toast.show({
                    icon: 'success',
                    content: '删除成功'
                })
                getData();
            })
            .catch(() => Toast.show({
                icon: 'fail',
                content: '删除失败，请稍后重试'
            }))
    }

    const itemActions = [
        {
            key: 'edit',
            text: '编辑',
            color: '#EB833A',
        },
        {
            key: 'delete',
            text: '删除',
            color: '#FF7070',
        }
    ];

    const onAddressItemAction = (action: any, e: any, data: any) => {
        const key = action.key;
        if (key === 'edit') {
            setModalData(data);
        } else {
            deleteAddress(data.id);
        }
    }

    const AddressItem = ({data, index}: any) => {
        const {fullname, isDefault, province, city, district, street, address, phone} = data;
        return (
            <SwipeAction rightActions={itemActions}
                         onAction={(a, e) => onAddressItemAction(a, e, data)}>
                <div className='address-item' onClick={() => setSelectedIndex(index)}>
                    <div className='address-body'>
                        <div className='address-name-phone align-center'>
                            <span className='address-name'>{fullname}</span>
                            <span className='default'>{phone}</span>
                            {isDefault && <Tag color='var(--adm-color-primary)' style={{marginLeft: '8px'}}>默认</Tag>}
                        </div>
                        <p className='address-area'>{`${province}${city}${district}${street}${address}`}</p>
                    </div>
                    {index === selectedIndex && <CheckCircleFill fontSize={24} color='var(--adm-color-primary)'/>}
                </div>
            </SwipeAction>
        )
    }

    const AddressDivider = () => <div className='address-divider'/>

    const insertBetween = (arr: any[]) => {
        const result: any[] = [];
        if (!arr) return result;
        for (let i = 0; i < arr.length; i++) {
            result.push(<AddressItem data={arr[i]} index={i} key={arr[i].id}/>);
            if (i < arr.length - 1)
                result.push(<AddressDivider key={arr[i].id + 1}/>);
        }
        return result;
    }

    return (
        <div className='address-list-component'>
            <div className='address-list-tools'>
                <div className='align-center'>
                    <div className='address-list-title'>常用地址</div>
                    {data.length > 0 && <span className='address-explain'>向左滑动编辑地址信息</span>}
                </div>
                <div className='align-center' onClick={() => setModalData({isDefault: data.length === 0})}>
                    <span className='address-tool-text'>新增</span>
                </div>
            </div>
            <div className='address-list-body'>
                <div>
                    {data.length > 0 ? insertBetween(data) :
                        <div onClick={() => setModalData({isDefault: data.length === 0})}>
                            <Empty description='点击新增收货地址'/>
                        </div>}
                </div>
            </div>
            <div className='page-footer-button page-footer-button'
                 onClick={() => onChange(data[selectedIndex] || null)}>
                <span>确定</span>
            </div>
            <Popup visible={modelData !== null} destroyOnClose={true}
                   bodyClassName={localStorage.getItem('theme') === 'zhongji' ? 'zhongji-theme' : 'zhiyuan-theme'}
                   bodyStyle={{
                       height: '70vh',
                       borderTopLeftRadius: '20px',
                       borderTopRightRadius: '20px',
                       overflow: "hidden",
                   }}>
                <div className='popup-body'>
                    <AddressForm init={modelData} onClose={() => {
                        setModalData(null);
                        getData();
                    }}/>
                </div>
            </Popup>
        </div>
    )
}

export default AddressList;