import './index.css';
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectJsonRequest} from "../../../components/global";
import {Toast, Skeleton, SpinLoading, CheckList} from "antd-mobile";
import GoldCoin from "../../../components/GoldCoin";

function OrderPay({}: any) {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const transactionId = searchParams.get('transactionId')
    const jsonRequest = useSelector(selectJsonRequest);
    const [order, setOrder]: any = useState(null);
    const [loading, setLoading] = useState(false);
    const [tradeChannels, setTradeChannels]: any = useState([])
    const [selectedChannel, setSelectedChannel]: any = useState(null);

    useEffect(() => getOrder(), [])

    const getOrder = () => {
        jsonRequest.get(`/v1/user/order/${transactionId}`, {})
            .then(({data}: any) => {
                if (data.status != 0) {
                    Toast.show({
                        icon: 'fail',
                        content: '订单状态有误',
                        afterClose: () => window.history.back()
                    })
                } else {
                    setOrder(data);
                    getTradeChannel(data.currencyMeta);
                }
            })
            .catch((e) => Toast.show({
                icon: 'fail',
                content: '订单获取失败',
            }))
    }

    const getTradeChannel = (currencyMeta: any) => {
        jsonRequest.get(`/v1/user/order/${currencyMeta}/availableTradeChannel`, {currencyMeta})
            .then(({data}: any) => {
                setTradeChannels(data)
                setSelectedChannel(data.find((e: any) => e.default).trade_channel_meta)
            })
            .catch((e) => Toast.show({
                icon: 'fail',
                content: '支付方式获取失败',
            }))
    }

    const tackTrade = () => {
        if (!selectedChannel) {
            Toast.show({icon: 'fail', content: '请选择支付方式'})
            return;
        }
        setLoading(true);
        const channel = tradeChannels.find((e: any) =>
            e.trade_channel_meta === selectedChannel);
        jsonRequest.post(`/v1/user/order/${transactionId}/payment`, {
            transactionId,
            tradeChannelMeta: selectedChannel,
            tradeChannelName: channel.trade_channel_name,
            currencyName: channel.currency_name,
        })
            .then(({code, message}: any) => {
                if (code >= 500) {
                    Toast.show({
                        icon: 'fail',
                        content: code === 500 ? message : '支付失败',
                        afterClose: () => setLoading(false),
                    });
                    return;
                }
                Toast.show({
                    icon: 'success',
                    content: '支付成功',
                    afterClose: () => {
                        if (searchParams.get('type') === 'back')
                            window.history.back();
                        else
                            navigate(`/orderDetail?id=${transactionId}`, {replace: true})
                    }
                });
            })
            .catch((e) => {
                setLoading(false);
                Toast.show({icon: 'fail', content: '下单失败，请稍后重试'});
            })
    }

    const TradeList = ({list}: any) => {
        return (
            <CheckList className='trade-check-list' value={[selectedChannel]}
                       onChange={(v: any) => v[0] && setSelectedChannel(v[0])}>
                {list.map((e: any) =>
                    <CheckList.Item value={e.trade_channel_meta} key={e.trade_channel_meta}>
                        <div className='trade-channel-name'>{e.trade_channel_name}</div>
                        <div className='trade-balance sub-text'>{`余额：${e.available_balance} ${e.currency_unit}`}</div>
                    </CheckList.Item>)}
            </CheckList>
        )
    }

    return (
        <div className='order-pay-page'>
            {
                order == null ?
                    <div style={{padding: '10px'}}>
                        <Skeleton.Title animated/>
                        <Skeleton.Paragraph lineCount={5} animated/>
                    </div> :
                    <div>
                        <div className='order-pay-body'>
                            <div className='order-amount-line'>
                                <GoldCoin size={24} className='pay-amount-coin'/>
                                <span className='pay-amount'>-{order.totalAmount}</span>
                            </div>
                            <div className='order-pay-box' style={{marginTop: 0}}>
                                {tradeChannels.length > 0 && <TradeList list={tradeChannels}/>}
                            </div>
                        </div>
                        <div className='add-address-button page-footer-button' onClick={() => !loading && tackTrade()}>
                            <span>{loading ?
                                <SpinLoading style={{'--size': '20px', '--color': '#fff'}}/> : '确认支付'}</span>
                        </div>
                    </div>
            }
        </div>
    )
}

export default OrderPay;