import {useDispatch, useSelector} from "react-redux";
import {Outlet, useSearchParams} from "react-router-dom";
import {selectUser, setClient, signIn} from "./components/global";
import JsonRequest from "./components/jsonRequest";
import Navigate from "./components/navigate";
import './App.css'

function Layout(params: any) {
    const [searchParams] = useSearchParams();
    const payload = searchParams.get("payload");
    const kid = {kid: searchParams.get("kid")};
    const user = useSelector(selectUser)
    const dispatch = useDispatch()

    const paddingTop = searchParams.get("paddingTop")
    const paddingBottom = searchParams.get("paddingBottom")
    const isTitleBarShow = searchParams.get("isTitleBarShow")
    const theme = searchParams.get("theme")

    if (paddingTop) {
        localStorage.setItem("paddingTop", paddingTop);
        searchParams.delete('paddingTop')
    }
    if (paddingBottom) {
        localStorage.setItem("paddingBottom", paddingBottom);
        searchParams.delete('paddingBottom')
    }
    if (isTitleBarShow) {
        localStorage.setItem("isTitleBarShow", isTitleBarShow);
        searchParams.delete('isTitleBarShow')
    }
    if (theme) {
        localStorage.setItem("theme", theme);
        searchParams.delete('theme')
    }

    if (!user || payload) {
        const client = JsonRequest.rawClient(kid)

        client.post("/v1/session/", {payload: payload})
            .then(({data}: any) => {
                // 防止一直重复请求
                searchParams.delete('kid');
                searchParams.delete('payload');
                localStorage.setItem("mall-kid", JSON.stringify(kid));
                dispatch(setClient(JsonRequest.tokenClient(data, kid)))
                dispatch(signIn(data || {}))
            })
        return <>
        </>
    } else {
        return (
            <div
                className={`mall-layout ${localStorage.getItem('theme') === 'zhongji' ? 'zhongji-theme' : 'zhiyuan-theme'}`}>
                {localStorage.getItem('isTitleBarShow') !== 'false' && <Navigate/>}
                <Outlet/>
            </div>
        )
    }
}

export default Layout