import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc)
dayjs.extend(timezone)

const orderStatusMap: any = {
    0: '待支付',
    1: '已取消',
    2: '已超时',
    3: '已取消',
    10: '已支付',
    20: '已发货',
    30: '已完成',
    40: '已完成部分退款',
    50: '已完成全部退款'
}

const orderStatusColorMap: any = {
    0: 'danger',
    1: 'default',
    2: 'default',
    3: 'default',
    10: 'success',
    20: 'warning',
    30: 'success',
    40: 'success',
    50: 'success'
};

const localTime = (time: string) => dayjs.tz(time, 'UTC').tz(dayjs.tz.guess())
    .format("YYYY-MM-DD HH:mm:ss")

export {
    orderStatusMap,
    orderStatusColorMap,
    localTime,
}