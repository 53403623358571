import './index.css';
import {Cascader, Form, Input, SpinLoading, Switch, TextArea, Toast} from "antd-mobile";
import {useState} from "react";
import {Options} from "../../addressOptions";
import {useSelector} from "react-redux";
import {selectJsonRequest} from "../../global";
import {LeftOutline} from "antd-mobile-icons";

function AddressForm({init, onClose}: any) {
    const [form] = Form.useForm();
    const [pickerVisible, setPickerVisible] = useState(false);
    const addressOptions = Options();
    const jsonRequest = useSelector(selectJsonRequest);
    const [loading, setLoading] = useState(false);

    const onFinish = () => {
        setLoading(true);
        const {address, city, district, isDefault, fullname, phone, province, street} = form.getFieldsValue(true);
        const data = {
            id: init.id,
            country: '中国',
            city,
            district,
            province,
            street,
            address,
            isDefault: isDefault || false,
            fullname,
            phone,
        };
        jsonRequest.post(`/v1/address/${init.id ? 'update' : ''}`, data)
            .then((_) => {
                Toast.show({icon: 'success', content: `${init.id ? '编辑' : '添加'}地址信息成功`});
                onClose();
                setLoading(false);
            })
            .catch(_ => {
                Toast.show({icon: 'fail', content: `${init.id ? '编辑' : '添加'}地址信息失败，请稍后重试`});
                setLoading(false);
            })
    }

    const checkArea = (_: any) => {
        const {province, city, district, street} = form.getFieldsValue(true);
        if (!!province && !!city && !!district && !!street)
            return Promise.resolve();
        return Promise.reject(new Error(!!province ? '地区信息不完整' : '地区不能为空'));
    }

    const onPhoneChange = (v: any) => {
        let value = v;
        if (v.toString().length > 11)
            value = v.toString().substring(0, 11);
        form.setFieldValue('phone', value);
        form.validateFields(['phone'])
    }

    return (
        <div className='add-address-page'>
            <div className='add-address-header'>
                <LeftOutline color='#333333' onClick={onClose}/>
                <span>新增地址</span>
                <LeftOutline style={{opacity: 0}}/>
            </div>
            <Form className='add-address-form' form={form} layout='horizontal'
                  initialValues={init}>
                <div className='add-address-body'>
                    <Form.Item name='isDefault' label='默认收货地址'>
                        <Switch style={{'--height': '24px', '--width': '48px'}} defaultChecked={init?.isDefault}/>
                    </Form.Item>
                    <Form.Item name='fullname' label='收货人' rules={[{required: true, message: '收件人不能为空'}]}>
                        <Input placeholder='请输入收货人姓名'/>
                    </Form.Item>
                    <Form.Item name='phone' label='手机号'
                               rules={[{required: true, message: '手机号不能为空'},
                                   {len: 11, message: '手机号必须是11位'}]}>
                        <Input type='number' placeholder='请输入收货人手机号' onChange={(v) => onPhoneChange(v)}/>
                    </Form.Item>
                    <Form.Item label='地区' name='area' onClick={() => setPickerVisible(true)}
                               rules={[{required: true, validator: checkArea}]}>
                        <Input readOnly placeholder='请选择地区'
                               value={((form.getFieldValue('province') || '') + (form.getFieldValue('city') || '')
                                       + (form.getFieldValue('district') || '') + (form.getFieldValue('street') || ''))
                                   || init && (init.province + init.city + init.district + init.street) || ''}/>
                        <Cascader
                            className={localStorage.getItem('theme') === 'zhongji' ? 'zhongji-theme' : 'zhiyuan-theme'}
                            options={addressOptions}
                            visible={pickerVisible}
                            onClose={() => setPickerVisible(false)}
                            onConfirm={v => {
                                form.setFieldsValue({
                                    area: `${v[0]} ${v[1]} ${v[2]} ${v[3]}`,
                                    province: v[0],
                                    city: v[1],
                                    district: v[2],
                                    street: v[3],
                                })
                            }}
                        />
                    </Form.Item>
                    <Form.Item name='address' label='详细地址' rules={[{required: true, message: '地址不能为空'}]}>
                        <TextArea placeholder='请输入详细地址' maxLength={100} autoSize rows={1}/>
                    </Form.Item>
                </div>
            </Form>
            <div className='add-address-button page-footer-button'
                 onClick={
                     () => form.validateFields()
                         .then((_) => !loading && onFinish())
                         .catch((_) => {
                         })}>
                <span>{loading ? <SpinLoading style={{'--size': '20px', '--color': '#fff'}}/> : '保存'}</span>
            </div>
        </div>
    )
}

export default AddressForm;