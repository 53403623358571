import './index.css';
import {useLocation} from "react-router-dom";
import ArrowBack from "../../images/arrow-back-dark.png";
import {SafeArea} from "antd-mobile";

interface PathMap {
    [key: string]: string;
}

function Navigate(params: any) {
    const location = useLocation();
    const paddingTop = parseInt(localStorage.getItem('paddingTop') || '0');
    const pathMap: PathMap = {
        '/submitOrder': '兑换',
        '/orderList': '我的订单',
        '/orderDetail': '订单',
        '/orderPay': '支付订单',
        '/orderExpress': '物流信息',
    }

    const goBack = () => {
        window.history.back();
    }

    return (
        <div className='navigate' style={{paddingTop: `${paddingTop}px`}}>
            {!paddingTop && <SafeArea position="top"/>}
            <div>
                <img src={ArrowBack} className='arrow-img' onClick={goBack} alt='返回'/>
                <span className='navigate-title'>{pathMap[location.pathname] || ''}</span>
                <img src={ArrowBack} className='arrow-img' style={{opacity: 0}} alt='返回'/>
            </div>
        </div>
    )
}

export default Navigate;