import './index.css';
import {AutoCenter, Empty, List, SpinLoading, Toast} from "antd-mobile";
import {useSearchParams} from "react-router-dom";
import {localTime, orderStatusMap} from "../../../components/util";
import OrderItem from "../../../components/order/item";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectJsonRequest} from "../../../components/global";

function OrderDetail(params: any) {
    const jsonRequest = useSelector(selectJsonRequest)
    const [detail, setDetail]: any = useState(null);
    const [searchParam] = useSearchParams();
    const [loading, setLoading]: any = useState(true);

    useEffect(() => getDetail(), [])

    const getDetail = () => {
        jsonRequest.get(`/v1/user/order/${searchParam.get('id')}`, {})
            .then(({data}: any) => {
                setLoading(false)
                setDetail(data);
            })
            .catch((e) => {
                setLoading(false)
                Toast.show({
                    icon: 'fail',
                    content: '数据获取失败',
                })
            })
    }

    const copyStr = (str: string) => {
        //Create new element
        const elm = document.createElement('textarea');
        //Fill the new element with text
        elm.value = str;
        //Append the new element
        document.body.appendChild(elm);
        //Select the content of the element
        elm.select();
        //Copy the content
        document.execCommand('copy');
        //Remove the element
        document.body.removeChild(elm);
        Toast.show({
            content: '已复制',
            position: 'bottom',
        })
    }

    const CopyIcon = () => {
        return <span className='copy-icon'>
            复制
        </span>
    }

    const Detail = () => (
        <div className='order-detail-page'>
            <OrderItem data={detail} onRefresh={getDetail} detail/>
            <List mode='card'>
                <List.Item extra={<div style={{textAlign: 'right'}}>{detail.transactionId}</div>}
                           onClick={() => copyStr(detail.transactionId)}
                           arrow={<CopyIcon/>}>订单编号</List.Item>
                <List.Item extra={`${detail.totalAmount}积分`}>实付款</List.Item>
                {detail.buyerMessage && <List.Item extra={detail.buyerMessage}>订单备注</List.Item>}
            </List>
            {
                detail.shippingManifest && <List mode='card'>
                    <List.Item extra={detail.shippingManifest.releaseChannel?.channelName}>快递公司</List.Item>
                    <List.Item extra={detail.shippingManifest.expressNo}
                               onClick={() => copyStr(detail.shippingManifest.expressNo)}
                               arrow={<CopyIcon/>}>快递单号</List.Item>
                    <List.Item extra={localTime(detail.shippingManifest.logisticsCreateAt)}>发货时间</List.Item>
                </List>
            }
            <List mode='card'>
                <List.Item extra={orderStatusMap[detail.status]}>订单状态</List.Item>
                <List.Item extra={detail.currencyName}>支付方式</List.Item>
                <List.Item extra={localTime(detail.orderedAt)}>下单时间</List.Item>
                {detail.paidAt &&
                    <>
                        <List.Item extra={detail.tradeChannelName}>支付渠道</List.Item>
                        <List.Item extra={localTime(detail.orderedAt)}>支付时间</List.Item>
                    </>
                }
            </List>
            {
                detail.address && <List mode='card'>
                    <List.Item extra={detail.address.fullname}>收件人</List.Item>
                    <List.Item extra={detail.address.phone}>收件电话</List.Item>
                    <List.Item
                        extra={`${detail.address.province} ${detail.address.city} ${detail.address.district} ${detail.address.street} ${detail.address.address}`}>配送地址</List.Item>
                </List>
            }
        </div>
    )

    const Loading = () => <AutoCenter style={{marginTop: '40px'}}><SpinLoading/></AutoCenter>

    const DataEmpty = () => <Empty style={{padding: '64px 0'}} imageStyle={{width: 128}} description='暂无数据'/>

    return loading ? <Loading/> : detail == null ? <DataEmpty/> : <Detail/>
}

export default OrderDetail;