import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Layout from './Layout';
import SubmitOrder from "./pages/order/submit-order";
import OrderList from "./pages/order/list";
import OrderDetail from "./pages/order/detail";
import OrderPay from "./pages/order/pay";
import 'dayjs/locale/zh-cn';
import OrderExpress from "./pages/order/express";

function App() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route path='/orderList' element={<OrderList/>}/>
                    <Route path='/orderDetail' element={<OrderDetail/>}/>
                    <Route path='/orderPay' element={<OrderPay/>}/>
                    <Route path='/submitOrder' element={<SubmitOrder/>}/>
                    <Route path='/orderExpress' element={<OrderExpress/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
