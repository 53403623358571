import './index.css';
import {AutoCenter, Empty, SpinLoading, Toast} from "antd-mobile";
import {useSelector} from "react-redux";
import {selectJsonRequest} from "../../../components/global";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import OrderItem from "../../../components/order/item";

function OrderList(params: any) {
    const jsonRequest = useSelector(selectJsonRequest)
    const navigate = useNavigate();
    const [list, setList]: any[] = useState([]);
    const pageSize = 10;
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [noData, setNoData] = useState(false);

    useEffect(() => getData(), [])

    const refresh = () => {
        jsonRequest.get('/v1/user/order/', {page: 1, pageSize})
            .then(({data}: any) => {
                setPage(page + 1);
                const newList = [...data.data];
                setHasMore(data.count > newList.length)
                setList(newList);
            })
            .catch((e) => Toast.show({
                icon: 'fail',
                content: '数据获取失败',
            }))
    }

    const getData = () => {
        jsonRequest.get('/v1/user/order/', {page: page + 1, pageSize})
            .then(({data}: any) => {
                if (data.count == 0) {
                    setNoData(true);
                    return;
                }
                setPage(page + 1);
                const newList = [...list, ...data.data];
                setHasMore(data.count > newList.length)
                setList(newList);
            })
            .catch((e) => Toast.show({
                icon: 'fail',
                content: '数据获取失败',
                afterClose: () => setNoData(true)
            }))
    }

    return (
        <div className='my-order-page'>
            {/*<Tabs>*/}
            {/*    <Tabs.Tab title='全部' key={null}/>*/}
            {/*    <Tabs.Tab title='待收货' key={1}/>*/}
            {/*    <Tabs.Tab title='已完成' key={2}/>*/}
            {/*</Tabs>*/}
            <div className='order-list-page' id='order-list-page'>
                {
                    noData ? <Empty description='暂无订单数据'/> :
                        <InfiniteScroll dataLength={list.length}
                                        next={() => getData()}
                                        hasMore={hasMore}
                                        loader={<AutoCenter style={{marginTop: '40px'}}><SpinLoading/></AutoCenter>}
                                        scrollableTarget='order-list-page'
                                        endMessage={<div className='no-more-record'>没有更多了...</div>}>
                            {list.map((e: any) => <OrderItem data={e} key={e.id}
                                                             onRefresh={refresh}
                                                             onClick={() => navigate(`/orderDetail?id=${e.transactionId}`)}/>)}
                        </InfiniteScroll>
                }
            </div>
        </div>
    )
}

export default OrderList;