export function Options(): [] {
    const data = require('../data/pcas-code.json');
    return data.map((e: any) => getOption(e));
}

function getOption(e: any) {
    return {
        label: e.name,
        value: e.name,
        children: e.children && e.children.map((c:any) => getOption(c))
    }
}