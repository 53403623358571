import './index.css';
import Enter from '../../../images/enter.png';
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectJsonRequest} from "../../../components/global";
import {Checkbox, DotLoading, Input, Popup, Skeleton, SpinLoading, Stepper, Toast} from "antd-mobile";
import AddressList from "../../../components/address/list";
import GoldCoin from "../../../components/GoldCoin";

function SubmitOrder(params: any) {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const productId = searchParams.get("productId");
    const jsonRequest = useSelector(selectJsonRequest);

    const [detail, setDetail] = useState<any>(null);

    const [addressListVisible, setAddressListVisible] = useState(false);
    const [defAddressLoading, setDALoading] = useState(false);
    const [address, setAddress] = useState<any>(null);

    const [orderLoading, setOrderLoading] = useState(true);
    const [orderCount, setOrderCount] = useState(1);
    const [orderRemark, setOrderRemark] = useState('');

    useEffect(() => {
        getDefaultAddress();
        getDetail();
    }, [])

    const getDefaultAddress = () => {
        setDALoading(true);
        jsonRequest.get('/v1/user/address/', {})
            .then(({data}: any) => {
                setAddress(data);
                setDALoading(false);
            })
            .catch(_ => {
                setDALoading(false);
                Toast.show({icon: 'fail', content: '数据获取失败'});
                setAddress(null);
            })
    }

    const getDetail = () => {
        jsonRequest.get(`/v1/user/product/${productId}`, {})
            .then(({data}: any) => {
                const sku = data.productSkuDTO[0] || {};
                const product = {
                    id: sku.id,
                    productName: data.productName,
                    img: sku.images[0]?.objName,
                    price: sku.productPrice,
                    stock: sku.productStock,
                    currencyMeta: data.currencyMeta,
                    currencyName: data.currencyName,
                };
                setDetail(product);
                setOrderLoading(false);
            })
            .catch(_ => Toast.show({icon: 'fail', content: '数据获取失败'}))
    }

    const tackOrder = () => {
        if (address == null) {
            Toast.show({
                content: '请选择收货地址！',
                duration: 1000,
                afterClose: () => setAddressListVisible(true),
            })
            return;
        }
        setOrderLoading(true);
        const data = {
            buyerMessage: orderRemark,
            discountAmount: 0,
            shippingType: 0, // 0快递 1自提 2网络
            currencyMeta: detail.currencyMeta,
            currencyName: detail.currencyName,
            totalAmount: detail.price * orderCount,
            paymentAmount: detail.price * orderCount,
            addressId: address.id,
            orderDetailList: [{
                productSkuId: detail.id,
                quantity: orderCount,
            }]
        };
        jsonRequest.post('/v1/user/order/', data)
            .then(({code, message}: any) => {
                if (code >= 500) {
                    Toast.show({
                        icon: 'fail',
                        content: message,
                        afterClose: () => setOrderLoading(false),
                    });
                    return;
                }
                Toast.show({
                    icon: 'success',
                    content: '下单成功，即将前往支付',
                    afterClose: () => navigate(`/orderPay?transactionId=${message}`, {replace: true})
                });
                // 因为有goback 所以不需要取消loading 防止手贱继续点
                // setOrderLoading(false);
            })
            .catch((e) => {
                setOrderLoading(false);
                Toast.show({icon: 'fail', content: '下单失败，请稍后重试'});
            })
    }

    const AddressCard = () => {
        if (defAddressLoading)
            return (
                <div className='order-box' onClick={() => setAddressListVisible(true)}>
                    <DotLoading color='primary'/>
                </div>
            )
        const {fullname, province, city, district, street, phone} = address || {};
        return (
            <div onClick={() => setAddressListVisible(true)} className='order-box address-box'>
                {
                    address == null ?
                        <p className='add-address'>点击添加收货地址</p>
                        :
                        <div className='address'>
                            <div>
                                <span className='address-fullname'>{fullname}</span>
                                <span>{phone}</span>
                            </div>
                            <span>{province}{city}{district}{street}{address.address}</span>
                        </div>
                }
                <img src={Enter} alt="enter" className='enter'/>
            </div>
        )
    }

    const ProductCard = () => {
        if (!detail)
            return (
                <div>
                    <Skeleton.Title animated/>
                    <Skeleton.Paragraph lineCount={2} animated/>
                </div>
            )
        return (
            <div className='goods-info'>
                <img src={detail.img} alt="商品图片" className='goods-avatar'/>
                <div className='goods-info-description'>
                    <p className='goods-name'>{detail.productName || ''}</p>
                    <div className='align-center'>
                        <GoldCoin size={20} className='gold-coin'/>
                        <span className='goods-price'>{detail.price || 0}</span>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='submit-page'>
            <div className='order-info width-limit'>
                <ProductCard/>
                <AddressCard/>
                <div className='order-box'>
                    <div className='order-space-between'>
                        <b>数量</b>
                        <Stepper value={orderCount} min={1} max={detail?.stock || 1}
                                 onChange={(v) => setOrderCount(v > 0 ? v : 1)}/>
                    </div>
                    <div className='order-space'/>
                    <div className='order-space-between'>
                        <b>订单备注</b>
                        <Input className='order-remarks' placeholder='无备注' value={orderRemark}
                               onChange={setOrderRemark}/>
                    </div>
                </div>
                <div className='order-box'>
                    <div className='order-space-between'>
                        <div className='align-center'>
                            <GoldCoin size={30} className='gold-coin'/>
                            <b className='pay-mode'>积分支付</b>
                        </div>
                        <Checkbox checked={true}/>
                    </div>
                </div>
            </div>
            <div className='order-pay'>
                <div className='width-limit'>
                    <div className='order-pay-info'>
                        <span>小计</span>
                        <span>{detail?.price * orderCount || 0}积分</span>
                    </div>
                    <div className='pay-button' onClick={() => !orderLoading && tackOrder()}>
                        {orderLoading ? <SpinLoading style={{'--size': '20px', '--color': '#fff'}}/> :
                            <span>立即下单</span>}
                    </div>
                </div>
            </div>
            <Popup visible={addressListVisible} destroyOnClose={true} onMaskClick={() => setAddressListVisible(false)}
                   bodyClassName={localStorage.getItem('theme') === 'zhongji' ? 'zhongji-theme' : 'zhiyuan-theme'}
                   bodyStyle={{
                       height: '70vh',
                       borderTopLeftRadius: '20px',
                       borderTopRightRadius: '20px',
                       overflow: "hidden",
                   }}>
                <div className='popup-body'>
                    <AddressList initSelected={address} onChange={(v: any) => {
                        setAddress(v);
                        setAddressListVisible(false);
                    }}/>
                </div>
            </Popup>
        </div>
    )
}

export default SubmitOrder;