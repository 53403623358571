import './index.css';
import {useNavigate} from "react-router-dom";
import {orderStatusColorMap, orderStatusMap} from "../../util";
import {useSelector} from "react-redux";
import {selectJsonRequest} from "../../global";
import {Toast} from "antd-mobile";
import dayjs from "dayjs";
import {useEffect, useState} from "react";
import GoldCoin from "../../GoldCoin";

function OrderItem({data, onClick, onRefresh, detail}: any) {
    const jsonRequest = useSelector(selectJsonRequest)
    const navigate = useNavigate();
    const {transactionId} = data;
    const [countDown, setCountDown] = useState(0);

    useEffect(() => {
        if (data.status == 0 && calculateCountDown()) {
            const id = setInterval(() => {
                if (!calculateCountDown()) clearTimeout(id)
            }, 1000 * 60);
            return () => clearTimeout(id);
        }
    }, [])

    const calculateCountDown = () => {
        const local = dayjs.tz(data.orderedAt, 'UTC').tz(dayjs.tz.guess())
        const diff = Math.trunc(dayjs().diff(local) / (1000 * 60))
        const limit = 30 - diff;
        if (limit >= 0) setCountDown(limit)
        return limit >= 0
    }

    const toTrade = () => {
        navigate(`/orderPay?transactionId=${transactionId}&type=back`)
    }

    const cancelOrder = () => {
        jsonRequest.post(`/v1/user/order/${transactionId}/cancel`, {transactionId})
            .then(({code, message}: any) => {
                if (code === 500) {
                    Toast.show({icon: 'fail', content: message});
                    return;
                }
                Toast.show({
                    icon: 'success',
                    content: '已取消订单',
                    afterClose: () => onRefresh && onRefresh(),
                });
            })
            .catch((_) => Toast.show({icon: 'fail', content: '操作失败，请稍后重试'}))
    }

    const confirmReceipt = () => {
        jsonRequest.get(`/v1/user/order/${transactionId}/complete`, {transactionId})
            .then(({code, message}: any) => {
                if (code === 500) {
                    Toast.show({icon: 'fail', content: message});
                    return;
                }
                Toast.show({
                    icon: 'success',
                    content: '已完成收货',
                    afterClose: () => onRefresh && onRefresh(),
                });
            })
            .catch((_) => Toast.show({icon: 'fail', content: '数据获取失败'}))
    }

    const OrderSku = ({sku}: any) => {
        const {productSku, productTitle, subtotal, quantity} = sku;
        const showOrderExpress = detail && [10, 20, 30, 40].includes(data.status) && data?.extendInfo?.[0]?.productSku?.type === 0
        return (
            <div>
                <div className='align-center order-item-info'>
                    <div className='order-goods-avatar'>
                        <img src={productSku.images[0]?.objName} alt="goods"/>
                    </div>
                    <span className='order-goods-name'>{productTitle}</span>
                    <div className=' order-goods-sub-info'>
                        <div className='order-goods-price-div align-center'>
                            <GoldCoin size={12}/>
                            <span className='order-goods-price'>{subtotal}</span>
                        </div>
                        <span className='order-goods-quantity'>{`共${quantity}件`}</span>
                    </div>
                </div>
                <div className='align-center order-tools'>
                    {showOrderExpress &&
                        <div className='tools-button order-express' onClick={(e) => {
                            navigate(`/orderExpress?id=${data.shippingManifest?.id || ''}`)
                            e.stopPropagation();
                        }}>
                            查看物流
                        </div>}
                    {data.status == 0 &&
                        <div className='tools-button cancel-order' onClick={(e) => {
                            cancelOrder();
                            e.stopPropagation();
                        }}>
                            取消订单
                        </div>}
                    {data.status !== 0 &&
                        <div className='tools-button buy-again' onClick={(e) => {
                            navigate(`/submitOrder?productId=${productSku.productId}`);
                            e.stopPropagation();
                        }}>
                            再次购买
                        </div>}
                    {data.status == 20 &&
                        <div className='tools-button tack-over' onClick={(e) => {
                            confirmReceipt();
                            e.stopPropagation();
                        }}>
                            确认收货
                        </div>}
                    {data.status == 0 &&
                        <div className='tools-button to-trade' onClick={(e) => {
                            toTrade();
                            e.stopPropagation();
                        }}>
                            立即支付
                        </div>}
                </div>
            </div>
        )
    }

    return (
        <div className='order-item' onClick={onClick}>
            <div className='order-status'>
                <span className='warning cancel-countdown'>
                    {countDown > 0 ? `${countDown}分钟后自动取消订单，请及时支付` : ''}
                </span>
                <span className={orderStatusColorMap[data.status]}>{orderStatusMap[data.status]}</span>
            </div>
            {data.extendInfo.map((e: any) => <OrderSku sku={e} key={e.id}/>)}
        </div>
    )
}

export default OrderItem;